<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="editApi.isError"
      :api="editApi"
      :callbackReset="() => (editApi.isError = false)"
    ></AError>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-row>
        <v-col cols="12" sm="6">
          <h2>Edit Student</h2>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex justify-center justify-sm-end">
            <div>
              <v-btn rounded color="red" outlined :to="{name:'PageEducatorInstitutionClassroomStudentDelete',params:{id:this.$route.params.id,parentId:this.$route.params.parentId}}"> Delete </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-card-title></v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <TextField
                :value="name"
                label="Full name"
                :rules="nameRules"
                type="text"
                @input="name = $event"
              ></TextField>
            </div>
            <div class="text-center py-0">
              <CheckBoxField :value="isActive" label="Active" @input="isActive = $event"></CheckBoxField>
            </div>
            <!-- <div class="text-center pt-3">
              <SelectField
                :value="mochiId"
                :field="selectField"
                @input="mochiId = $event"
              ></SelectField>
            </div> -->
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                :loading="editApi.isLoading"
                rounded
                color="primary"
                dark
                @click="submit()"
              >
                Submit
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="$router.go(-1)"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
// import SelectField from "../../components/Form/SelectField.vue";
import CheckBoxField from "@/components/Form/CheckBoxField.vue";
import TextField from "../../components/Form/TextField.vue";
export default {
  components: {
    // SelectField,
    TextField,
    CheckBoxField
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    selectField: {
      defaultValue: "",
      name: "Join with player",
      options: [],
      optionText: "mochiId",
      optionValue: "mochiId",
    },
    valid: true,
    name: null,
    isActive: false,
    isEnhancedPassword: false,
    password: null,
    nameRules: [],
    id: null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    playerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    editApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    mochiId: null,
  }),
  created() {

    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/"+this.$route.params.grandparentId+"/classroom/" +
      this.$route.params.parentId +
      "/student/" +
      this.$route.params.id;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.name = resp.name;
      this.isActive = resp.isActive
      this.mochiId = resp.mochiId;
      this.updateBreadcrumb(resp)
    };

    //EOC
    this.playerApi.method = "GET";
    this.playerApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/data/player";

    this.playerApi.callbackReset = () => {
      this.playerApi.isLoading = true;
      this.playerApi.isError = false;
    };

    this.playerApi.callbackError = (e) => {
      this.playerApi.isLoading = false;
      this.playerApi.isError = true;
      this.playerApi.error = e;
    };

    this.playerApi.callbackSuccess = (resp) => {
      this.playerApi.isLoading = false;
      resp.push({
        id: null,
        mochiId: "Select One",
      });
      this.selectField.options = resp.reverse();
    };
    this.editApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/classroom/" +
      this.$route.params.parentId +
      "/student/edit";

    this.editApi.callbackReset = () => {
      this.editApi.isLoading = true;
      this.editApi.isError = false;
    };

    this.editApi.callbackError = (e) => {
      this.editApi.isLoading = false;
      this.editApi.isError = true;
      this.editApi.error = e;
    };

    this.editApi.callbackSuccess = () => {
      this.editApi.isLoading = false;
      this.$router.push({ name: 'PageEducatorInstitutionClassroomStudentRead', params: {
        id:this.$route.params.id, 
        parentId:  this.$route.params.parentId,
        grandparentId:  this.$route.params.grandparentId, 
      } });
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.$api.fetch(this.api);
    this.$api.fetch(this.playerApi);
  },
  watch: {
    name: function () {
      this.nameRules = [];
    },
  },
  methods: {
    updateBreadcrumb(resp) {
      this.breadcrumbs.push({
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      });
      this.breadcrumbs.push({
        text: resp.Classroom.Institution.name,
        to: { name: "PageEducatorInstitutionDashboard",params:{id:this.$route.params.grandparentId} },
        exact: true,
      });
      this.breadcrumbs.push({
        text: 'Classrooms',
        to: { name: "PageEducatorInstitutionClassroom",params:{id:this.$route.params.grandparentId} },
        exact: true,
      });
      this.breadcrumbs.push({
        text: resp.Classroom.name,
        to: { name: "PageEducatorInstitutionClassroomDashboard" ,params:{id:this.$route.params.parentId,parentId:this.$route.params.grandparentId}},
        exact: true,
      });

      this.breadcrumbs.push({
      text: "Students",
      to: { name: "PageEducatorInstitutionClassroomStudent" ,params:{id:this.$route.params.parentId,parentId:this.$route.params.grandparentId} },
      exact: true,
    });
    this.breadcrumbs.push({
      text: resp.name,
      to: { name: "PageEducatorInstitutionClassroomStudentRead" ,params:{id:this.$route.params.id,parentId:this.$route.params.parentId,grandparentId:this.$route.params.grandparentId} },
      exact: true,
    });

    this.breadcrumbs.push({
      text: "Edit",
      to: { name: "PageEducatorInstitutionClassroomStudentEdit" },
      exact: true,
    });
    },
    close() {
      this.$router.push({ name: 'PageEducatorInstitutionClassroomStudent', params: { parentId:  this.$route.params.grandparentId,id:  this.$route.params.parentId } });
    },
    next() {
      this.step++;
    },
    back() {
      this.step--;
    },
    submit() {
      this.nameRules = [(v) => !!v || "Name is required"];
      let self = this;
      setTimeout(function () {
        if (self.$refs.formName.validate()) {
          self.editApi.params = {
            id: self.id,
            name: self.name,
            mochiId: self.mochiId,
            isActive: self.isActive,
          };
          self.$api.fetch(self.editApi);
        } else {
          self.editApi.callbackError("Please check the form.");
        }
      });
    },
    selectUserType(item) {
      this.mochiId = item.value;
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>